html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,textarea,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block;}body{line-height:1;}ol,ul{list-style:none;}blockquote,q{quotes:none;}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none;}table{border-collapse:collapse;border-spacing:0;}body{-webkit-text-size-adjust:none}mark{background-color:transparent;color:inherit}input::-moz-focus-inner{border:0;padding:0}input[type="text"],input[type="email"],select,textarea{-moz-appearance:none;-webkit-appearance:none;-ms-appearance:none;appearance:none}

*, *:before, *:after {
	box-sizing: border-box;
}

:root {

	--background-height: 100vh;
	--site-language-alignment: left;
	--site-language-direction: ltr;
	--site-language-flex-alignment: flex-start;
	--site-language-indent-left: 1;
	--site-language-indent-right: 0;
	--site-language-margin-left: 0;
	--site-language-margin-right: auto;
	--viewport-height: 100vh;


	/* --color-background: #FAFAFA; */
	--color-background: #F3F4F6;
	--color-backgroundSecondary: #ffffff;
	/* --color-backgroundTertiary: #ECECEC; */
	--color-backgroundTertiary: #E5E7EB;
	--color-backgroundInverse: #000000;
	
	--color-primary: #8b5cf6;
	--color-primaryHover: #B192FA; 
	--color-primaryLight: #D7C8FE;

  --color-text1: #000000;
  --color-text2: rgba(0,0,0,0.8);
	--color-text3: rgba(0,0,0,0.6);
  --color-text4: rgba(0,0,0,0.439);
  --color-textButton: #ffffff;
	--color-textButton2: rgba(255,255,255,0.9);
	--color-textInverse: #ffffff;
	
  --color-divider: rgba(0,0,0,0.09);
	--color-separator: #f4f4f4;
	--color-separatorOpaque: #e0e0e0;
	/* --color-separatorOpaque: #ECECEC; */

	--color-error: #ff0000;
	--color-white: #ffffff;
  --color-black: #000000;
  --color-shadow: rgba(0,0,0,0.1);
  --shadow: 0 0 10px var(--color-shadow);

	
	--borderShadow: 0 1px 2px 0 rgba(0,0,0,0.1);

	--aspectRatio-showcaseImage: 1.359;

	--paddingTop-mobile: 5rem;
	--header-height: 3.5rem;
}


 body {
	line-height: 1.0;
	min-height: var(--viewport-height);
	min-width: 320px;
	overflow-x: hidden;
	word-wrap: break-word;
	background-color: var(--color-background);
  font-family: 'Inter', sans-serif;
	overscroll-behavior: none; /* prevents the bounce/pull effect */
}

html {
	height: 100%;
	font-size: 15px;
	-webkit-text-size-adjust: 100% !important;
	background-color: var(--color-background);
  overscroll-behavior: none; /* prevents the bounce/pull effect */
	/* overflow-y: scroll;
  scrollbar-gutter: stable; */
}

u {
	text-decoration: underline;
}

strong {
	color: inherit;
	font-weight: bolder;
}

em {
	font-style: italic;
}

button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
	display: flex;
	align-items: center;
	justify-content: center;
}

deferred-script {
	display: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

#wrapper {
  /* height: 100vh; */
	/* height: 100%; */
	flex: 1;
	background-color: var(--color-backgroundSecondary);
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	
}

#main {
  flex: 1;
  width: 100%;
  position: relative;
  padding: 2rem; 
  background-color: var(--color-backgroundSecondary);
	display: flex;
	flex-direction: column;
}

#main > .inner {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;  /* Center the content */
  position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
}

@keyframes loading-spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

h1 span[style], h2 span[style], h3 span[style], p span[style], h1 strong, h2 strong, h3 strong, p strong, h1 a, h2 a, h3 a, p a, h1 code, h2 code, h3 code, p code {
	-webkit-text-fill-color: currentcolor;
}


.headerText {
	margin-left: var(--margin-left);
	margin-right: var(--margin-right);
	color: var(--color-text1);
	
	text-align: center;
	align-self: center;


	margin-top: 1.5rem;
	font-size: 3.4rem;
	font-weight: 700; 
	line-height: 1.1;  
	letter-spacing: -2px;
}

@keyframes button-spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* MODALS */


input[type="text"], input[type="number"], input[type="email"], input[type="tel"], input[type="password"], textarea {
	font-size: 16px;
}



@media (max-width: 2500px) {

}

@media (max-width: 1680px) {

}

@media (max-width: 1280px) {

}

@media (max-width: 1024px) {

}

/* TABLET */

@media (max-width: 980px) {
	#main {
		padding-top: 1rem;
	}

}

@media (max-width: 736px) {
	#main {
		padding: 1rem;
	}

	.headerText {
		font-size: 2.5rem;
		line-height: 1.3;
		letter-spacing: -1px;
	}
}

@media (max-width: 480px) {

	.headerText {
    font-size: 2rem;
	}
}


