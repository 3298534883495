/* MODALS */

.modalTitle {
	font-weight: 600;
	color: var(--color-text1);
	/* margin-top: 2rem; */
	font-size: 1.25rem;
}

.modalDescription {
	color: var(--color-text3);
	font-size: 16px;
	line-height: 1.4;
	margin-top: 0.5rem;
}

.modalFormLabel {
	font-weight: 500;
	color: var(--color-text3);
	font-size: 16px;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
	display: block;
	font-family: inherit;
}

.modalFormInput {
	border: 1px solid var(--color-separatorOpaque);
	background-color: white;
	width: 100%;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	height: 3rem;
	outline: none;
	border-radius: 0.5rem;
	font-size: 1rem;
	color: var(--color-text1);
	font-weight: 400;
	font-family: inherit;  
}

.modalButton {
	background-color: var(--color-text1);
	color: var(--color-white);
	border-radius: 0.5rem;
	margin-top: 1rem;
	width: 100%;
	font-weight: 500;
	font-size: 1rem;
	height: 3rem;
	border: none;
	font-family: inherit;
	cursor: pointer;
	padding: 0.5rem 1rem;
	transition: background-color 0.3s ease-in-out;
}

.modalButtonSecondary {
	background-color: var(--color-background);
	color: var(--color-text3);
	border: 1px solid var(--color-separatorOpaque);
	margin-top: 0.5rem;
}